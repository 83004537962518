import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './rootSlice'
import freePlayReducer from './freePlaySlice'
import fourFreePlayReducer from './fourFreePlaySlice'
import raceModeReducer from './raceModeSlice'
import modalReducer from './modalSlice'


export default configureStore({
  reducer: {
    root: rootReducer,
    fourFreePlay: fourFreePlayReducer,
    freePlay: freePlayReducer,
    modal: modalReducer,
    raceMode: raceModeReducer,
    // modal: modalReducer
  },
})