import { displaySecondsAsTime } from '../../utils/utils'
import { useSelector, useDispatch } from 'react-redux'


export default function RaceModeTopMenu() {
    const totalSolved = useSelector((state) => state.raceMode.totalSolved)
    const timeRemaining = useSelector((state) => state.raceMode.timeRemaining)

    return (
        <>
            <p style={{ width: '100%', margin: '0px' }}>
                <div
                    style={{ float: "left", paddingRight: "8px", textAlign: 'center' }}>
                    <p style={{ margin: '0px', fontSize: '14px' }}>Time</p>
                    <p style={{ margin: '0px', fontSize: '22px', fontWeight: 'bold' }}>{displaySecondsAsTime(timeRemaining)}</p>
                </div>
                <div
                    style={{ float: "right", paddingRight: "8px", textAlign: 'center' }}>
                    <p style={{ margin: '0px', fontSize: '14px' }}>Solved:</p>
                    <p style={{ margin: '0px', fontSize: '22px', fontWeight: 'bold' }}>{totalSolved}</p>
                </div>
            </p>
        </>
    )
}