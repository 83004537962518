import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { openModal, setModal, RACE_MODE_START_MODAL } from '../redux/modalSlice'
import { saveLeaderboardEntryToDB } from '../redux/rootSlice'
import CustomButton from '../CustomButton'
import { generateNickname } from '../utils/utils'
import { profanity } from '@2toad/profanity';
import ShareButton, { MODES } from '../ShareButton'


export default function RaceModeEndModal() {
    const allPhotos = useSelector((state) => state.root.allPhotos)
    const submittingToLeaderboard = useSelector((state) => state.root.loading.submittingToLeaderboard)
    const loadingLeaderboard = useSelector((state) => state.root.loading.leaderboard)
    const leaderboard = useSelector((state) => state.root.leaderboard)

    const [leaderboardName, setLeaderboardName] = useState(generateNickname())
    const [nameIsInvalid, setNameIsInvalid] = useState(false)
    // Edit these variables just for local testing!
    const currentPhoto = useSelector((state) => state.root.currentPhoto)
    const solvedPhotos = useSelector((state) => state.root.solvedPhotos)
    const totalSolved = useSelector((state) => state.raceMode.totalSolved)
    const dispatch = useDispatch()

    const startAgain = () => {
        dispatch(setModal(RACE_MODE_START_MODAL))
        dispatch(openModal())
    }

    const getTitle = () => {
        if (totalSolved == 0) {
            return 'Oops'
        }
        if (totalSolved == 1) {
            return 'Not bad!'
        }
        if (totalSolved == 2) {
            return 'Nice job!'
        }
        if (totalSolved == 3) {
            return 'Great job!'
        }
        if (totalSolved == 4) {
            return 'Amazing!'
        }
        if (totalSolved == 5) {
            return 'Wow!'
        }
        if (totalSolved == 6) {
            return "You're a genius!"
        }
        if (totalSolved == 7) {
            return 'Fantastic!'
        }
        if (totalSolved == 8) {
            return 'Hot dang!'
        }
        if (totalSolved == 9) {
            return "You're unstoppable!"
        }
        if (totalSolved == 10) {
            return "That's a lot of puzzles!"
        }
        if (totalSolved >= 11) {
            return "Are you sure you're not a robot?"
        }
    }

    const isValidName = (name) => {
        if (profanity.exists(name)) {
            return false
        }
        return true
    }

    const rank = () => {
        const previousLeaderBoard = leaderboard['race_mode'].slice(0, 10)
        let rank = 1;
        for (let i = 0; i < previousLeaderBoard.length; i++) {
            if (previousLeaderBoard[i].score < totalSolved) {
                return rank
            }
            rank = rank + 1
        }
        return rank
    }

    const saveLeaderboardEntry = () => {
        setNameIsInvalid(false)
        if (isValidName(leaderboardName)) {
            dispatch(saveLeaderboardEntryToDB({
                name: leaderboardName,
                score: totalSolved,
                mode: 'RACE_MODE'
            }
            ))
        }
        else {
            setNameIsInvalid(true)
        }
    }

    const didQualifyForLeaderboard = () => {
        if (totalSolved < 1) {
            return false
        }
        if (!loadingLeaderboard) {
            if (leaderboard['race_mode'].length < 10) {
                return true
            }
            const threshold = leaderboard['race_mode'].slice(-1)[0]['score']
            if (totalSolved > threshold) {
                return true
            }
        }
        return false
    }

    return (
        <>
            <h2>{getTitle()}</h2>
            <h3 style={{
                fontSize: '18px'
            }}>You solved <span>{totalSolved}</span> puzzle{totalSolved != 1 && (<>s</>)}</h3>
            {solvedPhotos.map((photo, index) => {
                if (photo >= currentPhoto - totalSolved && photo < currentPhoto) {
                    return <img
                        style={{ height: "40px", margin: '3px' }}
                        src={allPhotos[photo].image_url}
                    />
                }
            })}
            <br />
            <br />
            {didQualifyForLeaderboard() ?
                <>
                    <hr />
                    <div className="leaderboard-qualification-message">
                        <h3>
                            ✨ You qualified for the leaderboard! ✨
                        </h3>
                        <p>
                            Your nickname:
                            <input
                                type="text"
                                value={leaderboardName}
                                className="leader-name-input"
                                onChange={(event) => { setLeaderboardName(event.target.value) }}
                                maxLength={10}
                            />
                        </p>
                    </div>
                    <p style={{
                        display: 'flex',
                        justifyContent: 'space-around'
                    }}><CustomButton
                            text="Save and Continue"
                            onClick={() => saveLeaderboardEntry()}
                            spinner={submittingToLeaderboard}
                        /><ShareButton mode={MODES.RACE_LEADERBOARD} rank={rank()} /></p>
                    {nameIsInvalid && <p>Sorry, try another name</p>}
                </> :
                <button className="primary-button replay-button" onClick={() => startAgain()}><i class="fa-solid fa-rotate-right"
                    style={{ paddingRight: '8px' }}></i> Play Again</button>}
        </>
    )
}