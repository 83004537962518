import ReactModal from 'react-modal'
import { useSelector, useDispatch } from 'react-redux'
import RaceModeEndModal from './RaceModeEndModal'
import RaceModeStartModal from './RaceModeStartModal'
import LeaderboardModal from './LeaderboardModal'
import FreestyleAddToLeaderboardModal from './FreestyleAddToLeaderboardModal'
import { closeModal } from '../redux/modalSlice'

export default function CustomModal() {
    // Edit these variables just for local testing!    
    // const modalIsOpen = false
    // const currentModal = 'RACE_MODE_END_MODAL'
    const modalIsOpen = useSelector((state) => state.modal.isOpen)
    const currentModal = useSelector((state) => state.modal.currentModal)
    const dispatch = useDispatch()

    const modals = {
        RACE_MODE_END_MODAL: <RaceModeEndModal />,
        RACE_MODE_START_MODAL: <RaceModeStartModal />,
        LEADERBOARD_MODAL: <LeaderboardModal />,
        FREESTYLE_ADD_TO_LEADERBOARD_MODAL: <FreestyleAddToLeaderboardModal />
    }

    return (
        <ReactModal
            isOpen={modalIsOpen}
            className="modal rounded"
            overlayClassName="overlay"
        >
            <button
                onClick={() => dispatch(closeModal())}
                className="modal-button"
                style={{ border: 'none', backgroundColor: 'transparent' }}><i class="fa-solid fa-x"></i></button>
            {currentModal ? modals[currentModal] : ''}
        </ReactModal>
    )
}