import { useSelector, useDispatch } from 'react-redux'
import { displaySecondsAsTime } from '../utils/utils'
import { setModal, openModal, LEADERBOARD_MODAL } from '../redux/modalSlice'

export default function Leaderboard() {
    const leaderboard = useSelector((state) => state.root.leaderboard)
    const isLeaderboardLoading = useSelector((state) => state.root.loading.leaderboard)
    const currentMode = useSelector((state) => state.root.currentMode)
    const emojis = ['🥇', '🥈', '🥉', '4️⃣', '5️⃣', '6️⃣', '7️⃣', '8️⃣', '9️⃣', '🔟']
    const dispatch = useDispatch()

    let toDisplay = {}
    if (!isLeaderboardLoading) {
        if (currentMode == 'FREE_PLAY_MODE' || currentMode == 'MODE_SELECT') {
            toDisplay = leaderboard['freeplay_mode'].slice(0, 3)
        }
        else if (currentMode == 'RACE_MODE') {
            toDisplay = leaderboard['race_mode'].slice(0, 3)
        }
        else if (currentMode == 'FOUR_FREE_PLAY_MODE') {
            toDisplay = leaderboard['four_freeplay_mode'].slice(0, 3)
        }
        else {
            console.log('Unknown mode!', currentMode)
        }
    }

    const renderScore = (score) => {
        if (currentMode == 'FREE_PLAY_MODE' || currentMode == 'FOUR_FREE_PLAY_MODE') {
            return displaySecondsAsTime(score)
        }
        return score
    }

    if (currentMode == 'MODE_SELECT') {
        return (
            <div
                className="rounded"
                style={{ backgroundColor: 'rgba(239, 239, 239, .6)', padding: '8px', marginBottom: '8px' }}>
                <p><button
                    className="primary-button show-full-leaderboard-button"
                    onClick={() => {
                        dispatch(setModal(LEADERBOARD_MODAL))
                        dispatch(openModal())
                    }
                    }
                    style={{ width: '100%' }}>Leaderboard</button>
                </p>
            </div>
        )
    }

    return (
        <div
            className="rounded"
            style={{ backgroundColor: 'rgba(239, 239, 239, .6)', padding: '8px', marginBottom: '8px' }}>
            {isLeaderboardLoading ? (
                <div style={{
                    textAlign: 'center'
                }}>
                    <div className="spinner-border" role="status"
                        style={{
                            borderColor: '#2c3d50',
                            borderRightColor: 'transparent',
                        }}>
                        <span></span>
                    </div>
                </div>
            ) : (
                <ul className="leaderboard leaderboard-preview">
                    {toDisplay.map((value, index) => {
                        return (
                            <li><span className="medal-icon">{emojis[index]}</span> <span className="leader-name">{value.name}</span> <span className="leaderboard-stat">{renderScore(value.score)}</span></li>
                        )
                    })}
                    <p><button
                        className="primary-button show-full-leaderboard-button"
                        onClick={() => {
                            dispatch(setModal(LEADERBOARD_MODAL))
                            dispatch(openModal())
                        }
                        }
                        style={{ width: '100%' }}>Full Leaderboard</button></p>
                </ul>
            )
            }
        </div>

    )
}