import { useSelector, useDispatch } from 'react-redux'
import { displaySecondsAsTime } from '../utils/utils'

export default function LeaderboardModal() {
    const leaderboard = useSelector((state) => state.root.leaderboard)
    const isLeaderboardLoading = useSelector((state) => state.root.loading.leaderboard)

    const freePlay = leaderboard['freeplay_mode'].slice(0, 10)
    const fourFreePlay = leaderboard['four_freeplay_mode'].slice(0, 10)
    const raceMode = leaderboard['race_mode'].slice(0, 10)
    const emojis = ['🥇', '🥈', '🥉', '4️⃣', '5️⃣', '6️⃣', '7️⃣', '8️⃣', '9️⃣', '🔟']


    return (
        <div className="leaderboard-modal">
            <h1>Weekly Leaderboard</h1>
            <hr />
            {isLeaderboardLoading ? (
                <div style={{
                    textAlign: 'center'
                }}>
                    <div className="spinner-border" role="status"
                        style={{
                            borderColor: '#2c3d50',
                            borderRightColor: 'transparent',
                        }}>
                        <span></span>
                    </div>
                </div>
            ) : (
                <>
                    <h2 className="leaderboard-mode">Freestyle 3x3</h2>
                    <ul className="leaderboard full-leaderboard-section">
                        {freePlay.map((value, index) => {
                            return (
                                <li><span className="medal-icon">{emojis[index]}</span> <span className="leader-name">{value.name}</span> <span className="leaderboard-stat">{displaySecondsAsTime(value.score)}</span></li>
                            )
                        })}
                    </ul>
                    <hr />
                    <h2 className="leaderboard-mode">Freestyle 4x4</h2>
                    <ul className="leaderboard full-leaderboard-section">
                        {fourFreePlay.map((value, index) => {
                            return (
                                <li><span className="medal-icon">{emojis[index]}</span> <span className="leader-name">{value.name}</span> <span className="leaderboard-stat">{displaySecondsAsTime(value.score)}</span></li>
                            )
                        })}
                    </ul>
                    <hr />
                    <h2 className="leaderboard-mode">Race Mode</h2>
                    <ul className="leaderboard full-leaderboard-section">
                        {raceMode.map((value, index) => {
                            return (
                                <li><span className="medal-icon">{emojis[index]}</span> <span className="leader-name">{value.name}</span> <span className="leaderboard-stat">{value.score}</span></li>
                            )
                        })}
                    </ul>
                    <p>Leaderboard Restarts Mondays</p>
                </>
            )}
        </div>
    )
}