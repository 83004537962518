export function displaySecondsAsTime(rawSeconds) {
    const minutes = Math.floor(rawSeconds / 60)
    let seconds = rawSeconds % 60
    if (seconds < 10) {
        seconds = "0" + seconds
    }
    return `${minutes}:${seconds}`
}

export const modeToTitle = {
    FREE_PLAY_MODE: 'Freestyle 3x3',
    FOUR_FREE_PLAY_MODE: 'Freestyle 4x4',
    RACE_MODE: 'Race Mode'
}

// Returns a nickname of the form "AdjectiveNoun##" - the total length of the
// name needs to be 10 or less, which is enforced by making sure that:
// - Each adjective is 4 letters or fewer
// - Each noun is 4 letters or fewer
// - The number at the end is 2 digits or fewer
export function generateNickname() {
    // All adjectives need to be 4 letters or shorter
    const adjectives = ["Air", "Airy", "Baby", "Best", "Blue", "Bold", "Bony", "Busy", "Calm", "Cold", "Cool", "Cozy", "Cute", "Dark", "Dope", "Epic", "Evil", "Fast", "Fine", "Flat", "Foul", "Free", "Fun", "Fuzz", "Glad", "Glow", "Glum", "Gold", "Good", "Gray", "Grim", "Hazy", "Huge", "Hype", "Jazz", "Joy", "Keen", "Kind", "Loud", "Lush", "Meek", "Mild", "Nerd", "Neat", "New", "Nice", "Okay", "Old", "Pink", "Posh", "Punk", "Pure", "Rare", "Red", "Rich", "Ripe", "Rock", "Sad", "Safe", "Sass", "Sick", "Sly", "Soft", "Sour", "Tall", "Tame", "Tart", "Tiny", "Vain", "Wary", "Wild", "Wise", "Wry", "Zany"];
    // All nouns need to be 4 letters or shorter
    const nouns = ["Ant", "Ape", "Bat", "Bear", "Bee", "Boar", "Bull", "Cat", "Crab", "Crow", "Deer", "Dog", "Dodo", "Dove", "Duck", "Eel", "Elk", "Emu", "Fawn", "Fish", "Flea", "Fowl", "Fox", "Frog", "Gnat", "Goat", "Hare", "Hawk", "Hen", "Ibex", "Jay", "Jag", "Joey", "Kid", "Kit", "Koi", "Lamb", "Lark", "Lion", "Loon", "Lynx", "Mink", "Mole", "Mule", "Newt", "Owl", "Ox", "Pig", "Pika", "Pony", "Puma", "Pup", "Ram", "Rat", "Seal", "Stag", "Swan", "Toad", "Tuna", "Vole", "Wolf", "Worm", "Wren", "Yak"];
    const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
    const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
    // Generating a number less than 100 guarantees that the max length is 2 digits.
    const randomNum = Math.floor(Math.random() * 100);
    const nickname = `${randomAdjective}${randomNoun}${randomNum}`;
    return nickname;
}