import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import CustomModal from "./Modals/CustomModal";
import ModeSelectMenuMobile from "./Menus/ModeSelectMenuMobile";
import PuzzleGrid from "./PuzzleGrid";
import LeftMenuDesktop from "./Menus/LeftMenuDesktop";
import TopMenu from "./Menus/TopMenus/TopMenuDesktop";
import CaptionArea from "./CaptionArea";
import { useSelector, useDispatch } from 'react-redux'
import { addPhotos, submitScore } from './redux/rootSlice'
import { startFreePlayMode } from './redux/freePlaySlice'
import { displaySecondsAsTime } from './utils/utils'
import _ from "lodash";
import Modal from 'react-modal'
import BottomMenuMobile from "./Menus/BottomMenuMobile";


Modal.setAppElement("#root")

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export function App() {
  const allPhotos = useSelector((state) => state.root.allPhotos)
  const photoIndex = useSelector((state) => state.root.currentPhoto)
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(true)
  const currentPhoto = allPhotos[photoIndex]
  console.log("currentPhoto", currentPhoto)

  useEffect(() => {
    // localhost: 
    // fetch("http://127.0.0.1:8000/get-photos/")
    // Prod:
    fetch("https://animalscramble.wl.r.appspot.com/get-photos/")
      .then(response => response.json())
      .then(data => {
        console.log(data)
        data = _.shuffle(data)
        dispatch(addPhotos(data))
        dispatch(startFreePlayMode())
        setIsLoading(false)
      });
    // TODO: add catch and if so fall back to some default image links
    // const jsonData = await response.json();
    // console.log(jsonData);
  }, [])

  return (
    <div>
      <CustomModal />
      <ModeSelectMenuMobile />

      <nav className="navbar navbar-expand-lg bg-secondary text-uppercase fixed-top" id="mainNav">
        <div className="container">
          <a
            className="navbar-brand"
            href="#page-top"
            style={{
              fontFamily: 'Gloria Hallelujah',
              color: '#ffc107'
            }}>Animal Scramble</a>
          {/* <button class="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            Menu
            <i class="fas fa-bars"></i>
          </button> */}
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ms-auto">
              {/* <li class="nav-item mx-0 mx-lg-1"><a class="nav-link py-3 px-0 px-lg-3 rounded" href="#about">About</a></li>
              <li class="nav-item mx-0 mx-lg-1"><a class="nav-link py-3 px-0 px-lg-3 rounded" href="#contact">Contact</a></li> */}
            </ul>
          </div>
        </div>
      </nav>

      <section className="page-section bg-primary mb-0" id="about">
        <div className="container">
          <br />
          <div className="row">
            <LeftMenuDesktop />
            <div className="col-lg-8 me-auto">
              <TopMenu />
              {isLoading && (
                <div style={{
                  backgroundColor: 'rgb(56, 173, 150)',
                  maxWidth: '800px',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: '300px',
                }}
                  className="rounded"
                >
                  <div className="spinner-border" role="status"
                    style={{
                      borderColor: '#2c3d50',
                      borderRightColor: 'transparent'
                    }}>
                    <span></span>
                  </div>
                </div>
              )}
              {!isLoading && (
                <PuzzleGrid photo={currentPhoto} key={photoIndex} />
              )}
              <CaptionArea photo={currentPhoto} />
              <BottomMenuMobile />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;