import { createSlice } from '@reduxjs/toolkit'

import { setMode, showNextPhoto, RACE_MODE, savePhotoAsCompleted } from './rootSlice'
import { setModal, openModal, RACE_MODE_END_MODAL } from './modalSlice'

const lengthInSeconds = 120

const initialState = {
    totalSolved: 0,
    timeRemaining: lengthInSeconds,
    currentTimer: null,
    isTransitioningToNextPuzzle: false,
}

export const raceModeSlice = createSlice({
    name: 'raceMode',
    initialState: { ...initialState },
    reducers: {
        incrementNumberSolved: (state) => {
            state.totalSolved = state.totalSolved + 1
        },
        incrementTimer: (state) => {
            state.timeRemaining = state.timeRemaining - 1
        },
        saveTimer: (state, action) => {
            state.currentTimer = action.payload
        },
        setIsTransitioningToNextPuzzle: (state, action) => {
            state.isTransitioningToNextPuzzle = action.payload
        },
        refreshState: (state) => {
            state.timeRemaining = lengthInSeconds
            state.currentTimer = null
            state.totalSolved = 0
        },
        exitRaceMode: (state) => {
            clearInterval(state.currentTimer)
            state.timeRemaining = lengthInSeconds
            state.currentTimer = null
            state.totalSolved = 0
            state.isTransitioningToNextPuzzle = false
        },
    },
})

export const solvedCurrentPhoto = (data) => (dispatch, getState) => {
    console.log('Race mode solved current photo')
    dispatch(incrementNumberSolved())
    dispatch(setIsTransitioningToNextPuzzle(true))

    setTimeout(() => {
        dispatch(showNextPhoto())
        dispatch(setIsTransitioningToNextPuzzle(false))
    }, 3000)

    dispatch(savePhotoAsCompleted(getState().root.currentPhoto))
}

export const oneSecondPassed = (data) => (dispatch, getState) => {
    dispatch(incrementTimer())
    const state = getState().raceMode
    if (state.timeRemaining <= 0) {
        const gridster = window.gridsterInstance
        if (gridster) {
            gridster.disable()
        }
        clearInterval(state.currentTimer)
        dispatch(setModal(RACE_MODE_END_MODAL))
        dispatch(openModal())
    }
}

export const startRaceMode = (data) => (dispatch, getState) => {
    // You can access state like this:
    // const items = getState().ToDoItems.value
    console.log('start race mode')
    dispatch(setMode(RACE_MODE))
    dispatch(refreshState())
    dispatch(showNextPhoto())
    const time = setInterval(() => {
        dispatch(oneSecondPassed())
    }, 1000)
    dispatch(saveTimer(time))
    setIsTransitioningToNextPuzzle(false)

}

// Action creators are generated for each reducer function
export const {
    incrementNumberSolved,
    incrementTimer,
    saveTimer,
    setIsTransitioningToNextPuzzle,
    refreshState,
    saveBestTime,
    exitRaceMode,
} = raceModeSlice.actions

export default raceModeSlice.reducer