import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux'
import _ from "lodash";
import { solvedCurrentPhoto, FOUR_FREE_PLAY_MODE, FREE_PLAY_MODE, RACE_MODE } from './redux/rootSlice'

// TODO Change these from global variables to
// be properties of the class
// const PUZZLE_SIZE = 3;
// const NUM_TILES = PUZZLE_SIZE * PUZZLE_SIZE;
const TILE_MARGIN = 4;


export default function PuzzleGrid(props) {
  const currentMode = useSelector((state) => state.root.currentMode)
  const puzz_size = useRef(0)

  if (currentMode == FOUR_FREE_PLAY_MODE) {
    puzz_size.current = 4;
  }
  if (currentMode == FREE_PLAY_MODE || currentMode == RACE_MODE) {
    puzz_size.current = 3
  }

  const puzzle_size = puzz_size.current
  const num_tiles = puzzle_size * puzzle_size;
  const tileObjects = generateLayout(props.photo.image_url, num_tiles, puzzle_size);
  const [layout, setLayout] = useState(tileObjects)

  const [photoData, setPhotoData] = useState(props.photo)
  const [solved, setSolved] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const dispatch = useDispatch()

  const checkIfSolved = () => {
    const gridster = window.gridsterInstance

    if (!gridster) {
      return
    }
    const serializeOutput = gridster.serialize();
    const normalizedPositions = normalizePositions(serializeOutput);
    if (didWin(normalizedPositions, puzzle_size)) {
      console.log("You won!");
      fworks.launch();
      setSolved(true);
      dispatch(solvedCurrentPhoto())
      gridster.disable()
    } else {
      console.log("(you didn't win yet...)");
    }
  }

  const didWin = (normalizedPositions, puzzleSize) => {
    for (let i = 0; i < normalizedPositions.length; i++) {
      const currentPosition = normalizedPositions[i];
      const expectedNormalizedCol = getXForPosition(i, puzzleSize);
      const expectedNormalizedRow = getYForPosition(i, puzzleSize);
      if (expectedNormalizedCol != currentPosition.col) {
        // console.log("for i ", i, "expectedCol was ", expectedNormalizedCol, "but current col is ", currentPosition.col);
        return false;
      }
      if (expectedNormalizedRow != currentPosition.row) {
        // console.log("for i ", i, "expectedRow was ", expectedNormalizedRow, "but current row is ", currentPosition.row);
        return false;
      }
    }
    return true;
  }

  // Returns an array of objects, each in the format
  // {row: ..., col: ...}
  // Normalized so that the current top left tile always has {row: 0, col: 0}
  // (even if it is offset from the top left corner of the workspace by 1 or more units)
  const normalizePositions = (serializeOutput) => {
    const normalizedPositionArray = [];
    const minRowAndCol = getMinRowAndCol(serializeOutput);
    for (let i = 0; i < serializeOutput.length; i++) {
      const position = serializeOutput[i];
      normalizedPositionArray[i] = {
        row: position.row - minRowAndCol.row,
        col: position.col - minRowAndCol.col
      };
    }
    return normalizedPositionArray;
  }

  // Extracts the minimum row among all tiles in the grid,
  // and the minimum col.
  // Returns object in the format {row: ..., col: ...}
  const getMinRowAndCol = (serializeOutput) => {
    let minRow = null;
    let minCol = null;
    for (let i = 0; i < serializeOutput.length; i++) {
      const position = serializeOutput[i];
      if (minRow === null || position.row < minRow) {
        minRow = position.row;
      }
      if (minCol === null || position.col < minCol) {
        minCol = position.col;
      }
    }
    return {
      row: minRow,
      col: minCol
    };
  }


  useEffect(() => {
    const gridsterWidget = window.$(".gridster ul").gridster({
      widget_margins: [TILE_MARGIN, TILE_MARGIN],
      min_cols: puzzle_size + 1,
      extra_rows: 1,
      // min_rows: 4,
      widget_base_dimensions: [getTotalScaledWidth() / puzzle_size, getTotalScaledHeight() / puzzle_size],
      draggable: {
        stop: () => {
          checkIfSolved()
        }
      }
    }).data('gridster');
    window.gridsterInstance = gridsterWidget

    // Even though it looks like isLoading is never used, this call to set state forces
    // a re-render after gridster has initialized, which ensures that the dimensions of the 
    // grid are always correct (this is the resizing you see on mobile).
    setIsLoading(false)
  }, [])

  // Returns the final width of the PHOTO itself
  const getTotalScaledWidth = () => {
    // Max width is 500, and max height is 450. This function calculates what the width will be
    // given those constraints and the aspect ratio of the image.
    const gridWrapper = document.getElementById("gridWrapper")
    if (gridWrapper && gridWrapper.offsetWidth < 500) {
      // The screen is X pixels wide, so the picture can be no more than 3/4 of that 
      // (or 4/5 for a 4x4 puzzle)
      return ((puzzle_size / (puzzle_size + 1)) * gridWrapper.offsetWidth) - TILE_MARGIN * 6
    }
    // const wrapperWidth = document.getElementById("gridWrapper").offsetWidth
    // if (wrapperWidth < 500) {
    //   return wrapperWidth
    // }
    let width = 500
    let height = (width / photoData.width) * photoData.height
    if (height <= 450) {
      return 500
    }
    return (450 / photoData.height) * photoData.width
  }

  // Returns the final height of the PHOTO itself
  const getTotalScaledHeight = () => {
    const width = getTotalScaledWidth()
    const height = (width / photoData.width) * photoData.height // 500 / 1569 = .319. This times original height gets scaled height.
    return height

  }

  const generateCells = () => {

    const totalScaledWidth = getTotalScaledWidth()
    const totalScaledHeight = getTotalScaledHeight()

    const tileWidth = totalScaledWidth / puzzle_size
    const tileHeight = totalScaledHeight / puzzle_size
    const isSolved = solved

    // console.log('In Generate cells, this is info:')
    // console.log('tilewidth: ', tileWidth, 'tileHeight: ', tileHeight)
    // console.log('totalScaledWidth: ', totalScaledWidth, 'totalScaledHeight: ', totalScaledHeight)
    // console.log(NUM_TILES)
    return _.map(layout, function (l, i) {
      // Find the offset for this tile to only show that portion of the image.
      let topOffset = "0px"
      let leftOffset = "0px"
      let translateY = "0px"
      let translateX = "0px"

      if (num_tiles == 9) {
        if (l.i > 2 && l.i <= 5) {
          // 2nd row
          translateY = "-8px"
          topOffset = `-${tileHeight}px`
        }
        if (l.i > 5) {
          // 3rd row
          translateY = "-16px"
          topOffset = `-${tileHeight * 2}px`
        }

        if (l.i % 3 == 1) {
          // 2nd column
          translateX = "-8px"
          leftOffset = `-${tileWidth}px`
        }
        if (l.i % 3 == 2) {
          // 3rd column
          translateX = "-16px"
          leftOffset = `-${tileWidth * 2}px`
        }

        if (!isSolved) {
          translateY = "0px"
          translateX = "0px"
        }
      }
      else if (num_tiles == 16) {
        if (l.i > 3 && l.i <= 7) {
          // 2nd row
          translateY = "-8px"
          topOffset = `-${tileHeight}px`
        }
        if (l.i > 7 && l.i <= 11) {
          // 3rd row
          translateY = "-16px"
          topOffset = `-${tileHeight * 2}px`
        }
        if (l.i > 11) {
          // 4th row
          translateY = "-24px"
          topOffset = `-${tileHeight * 3}px`
        }

        if (l.i % 4 == 1) {
          // 2nd column
          translateX = "-8px"
          leftOffset = `-${tileWidth}px`
        }
        if (l.i % 4 == 2) {
          // 3rd column
          translateX = "-16px"
          leftOffset = `-${tileWidth * 2}px`
        }
        if (l.i % 4 == 3) {
          // 4th column
          translateX = "-24px"
          leftOffset = `-${tileWidth * 3}px`
        }

        if (!isSolved) {
          translateY = "0px"
          translateX = "0px"
        }
      }



      let styles = {}

      if (isSolved) {
        styles = {
          transform: `translate(${translateX}, ${translateY})`,
          boxShadow: "none",
          transition: 'transform 1s',
        }
      }

      return (
        <li
          style={styles}
          key={l.i}
          data-sizey={1}
          data-sizex={1}
          data-row={l.y + 1}
          data-col={l.x + 1}
        >
          <div
            style={{
              height: `${tileHeight}px`,
              width: `${tileWidth}px`,
              backgroundImage: `url('${l.imageURL}')`,
              backgroundSize: `${totalScaledWidth}px ${totalScaledHeight}px`,
              backgroundPosition: `top ${topOffset} left ${leftOffset}`
            }}
            id={l.i} alt={l.i} ></div>
        </li>
      );
    });
  }

  return (

    <div style={{ maxWidth: "800px" }} id="gridWrapper">
      <div className="gridster">
        <ul className="rounded" style={{ margin: "0px" }} >
          {generateCells()}
        </ul>
      </div>
    </div>
  )
}

function generateLayout(photoURL, num_tiles, puzzle_size) {
  // Array key/index is the index of the tile in the solved puzzle
  // Array value is the current position
  const tilePositions = [];

  // Initialize the tile positions before shuffling
  for (let i = 0; i < num_tiles; i++) {
    tilePositions[i] = i;
  }

  shuffle(tilePositions);

  const tileObjects = _.map(_.range(0, num_tiles), function (item, i) {
    return {
      x: getXForPosition(tilePositions[i], puzzle_size),
      y: getYForPosition(tilePositions[i], puzzle_size),
      i: i.toString(),
      imageURL: photoURL
    };
  });
  // i=0 | tilePositions[0]=5 | x=2 y=1
  // i=1 | tilePositions[1]=8 | x=2 y=2
  //...
  // i=8

  return tileObjects
}

// TODO: Rename XY stuff to rows and cols
// position: 4
// x: 1
// position: 2
// x: 2
function getXForPosition(position, puzzleSize) {
  return position % puzzleSize;
}

function getYForPosition(position, puzzleSize) {
  return Math.floor(position / puzzleSize);
}


// https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex]
    ];
  }

  return array;
}




// https://codepen.io/jackrugile/pen/nExVvo
// TODO: Move to separate file and remove unused functionality

var Fireworks = function () {
  /*=============================================================================*/
  /* Utility
  /*=============================================================================*/
  var self = this;
  var rand = function (rMi, rMa) { return ~~((Math.random() * (rMa - rMi + 1)) + rMi); }
  window.requestAnimFrame = function () { return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame || function (a) { window.setTimeout(a, 1E3 / 60) } }();

  /*=============================================================================*/
  /* Initialize
  /*=============================================================================*/
  self.init = function () {
    self.dt = 0;
    self.oldTime = Date.now();
    self.canvas = document.createElement('canvas');
    self.canvasContainer = $('#canvas-container');

    var canvasContainerDisabled = document.getElementById('canvas-container');
    self.canvas.onselectstart = function () {
      return false;
    };

    self.canvas.width = self.cw = 500;
    self.canvas.height = self.ch = 500;

    self.particles = [];
    self.partCount = 30;
    self.fireworks = [];
    self.mx = self.cw / 2;
    self.my = self.ch / 2;
    self.currentHue = 170;
    self.partSpeed = 5;
    self.partSpeedVariance = 10;
    self.partWind = 50;
    self.partFriction = 5;
    self.partGravity = 1;
    self.hueMin = 150;
    self.hueMax = 200;
    self.fworkSpeed = 2;
    self.fworkAccel = 4;
    self.hueVariance = 30;
    self.flickerDensity = 20;
    self.showShockwave = false;
    self.showTarget = true;
    self.clearAlpha = 25;

    self.canvasContainer.append(self.canvas);
    self.ctx = self.canvas.getContext('2d');
    self.ctx.lineCap = 'round';
    self.ctx.lineJoin = 'round';
    self.lineWidth = 1;
    self.canvasLoop();

    self.canvas.onselectstart = function () {
      return false;
    };


  };

  /*=============================================================================*/
  /* Particle Constructor
  /*=============================================================================*/
  var Particle = function (x, y, hue) {
    this.x = x;
    this.y = y;
    this.coordLast = [
      { x: x, y: y },
      { x: x, y: y },
      { x: x, y: y }
    ];
    this.angle = rand(0, 360);
    this.speed = rand(((self.partSpeed - self.partSpeedVariance) <= 0) ? 1 : self.partSpeed - self.partSpeedVariance, (self.partSpeed + self.partSpeedVariance));
    this.friction = 1 - self.partFriction / 100;
    this.gravity = self.partGravity / 2;
    this.hue = rand(hue - self.hueVariance, hue + self.hueVariance);
    this.brightness = rand(50, 80);
    this.alpha = rand(40, 100) / 100;
    this.decay = rand(10, 50) / 1000;
    this.wind = (rand(0, self.partWind) - (self.partWind / 2)) / 25;
    this.lineWidth = self.lineWidth;
  };

  Particle.prototype.update = function (index) {
    var radians = this.angle * Math.PI / 180;
    var vx = Math.cos(radians) * this.speed;
    var vy = Math.sin(radians) * this.speed + this.gravity;
    this.speed *= this.friction;

    this.coordLast[2].x = this.coordLast[1].x;
    this.coordLast[2].y = this.coordLast[1].y;
    this.coordLast[1].x = this.coordLast[0].x;
    this.coordLast[1].y = this.coordLast[0].y;
    this.coordLast[0].x = this.x;
    this.coordLast[0].y = this.y;

    this.x += vx * self.dt;
    this.y += vy * self.dt;

    this.angle += this.wind;
    this.alpha -= this.decay;

    if (this.alpha < .05) {
      self.particles.splice(index, 1);
    }
  };

  Particle.prototype.draw = function () {
    var coordRand = (rand(1, 3) - 1);
    self.ctx.beginPath();
    self.ctx.moveTo(Math.round(this.coordLast[coordRand].x), Math.round(this.coordLast[coordRand].y));
    self.ctx.lineTo(Math.round(this.x), Math.round(this.y));
    self.ctx.closePath();
    self.ctx.strokeStyle = 'hsla(' + this.hue + ', 100%, ' + this.brightness + '%, ' + this.alpha + ')';
    self.ctx.stroke();

    if (self.flickerDensity > 0) {
      var inverseDensity = 50 - self.flickerDensity;
      if (rand(0, inverseDensity) === inverseDensity) {
        self.ctx.beginPath();
        self.ctx.arc(Math.round(this.x), Math.round(this.y), rand(this.lineWidth, this.lineWidth + 3) / 2, 0, Math.PI * 2, false)
        self.ctx.closePath();
        var randAlpha = rand(50, 100) / 100;
        self.ctx.fillStyle = 'hsla(' + this.hue + ', 100%, ' + this.brightness + '%, ' + randAlpha + ')';
        self.ctx.fill();
      }
    }
  };

  /*=============================================================================*/
  /* Create Particles
  /*=============================================================================*/
  self.createParticles = function (x, y, hue) {
    var countdown = self.partCount;
    while (countdown--) {
      self.particles.push(new Particle(x, y, hue));
    }
  };

  /*=============================================================================*/
  /* Update Particles
  /*=============================================================================*/
  self.updateParticles = function () {
    var i = self.particles.length;
    while (i--) {
      var p = self.particles[i];
      p.update(i);
    };
  };

  /*=============================================================================*/
  /* Draw Particles
  /*=============================================================================*/
  self.drawParticles = function () {
    var i = self.particles.length;
    while (i--) {
      var p = self.particles[i];
      p.draw();
    };
  };

  /*=============================================================================*/
  /* Firework Constructor
  /*=============================================================================*/
  var Firework = function (startX, startY, targetX, targetY) {
    this.x = startX;
    this.y = startY;
    this.startX = startX;
    this.startY = startY;
    this.hitX = false;
    this.hitY = false;
    this.coordLast = [
      { x: startX, y: startY },
      { x: startX, y: startY },
      { x: startX, y: startY }
    ];
    this.targetX = targetX;
    this.targetY = targetY;
    this.speed = self.fworkSpeed;
    this.angle = Math.atan2(targetY - startY, targetX - startX);
    this.shockwaveAngle = Math.atan2(targetY - startY, targetX - startX) + (90 * (Math.PI / 180));
    this.acceleration = self.fworkAccel / 100;
    this.hue = self.currentHue;
    this.brightness = rand(50, 80);
    this.alpha = rand(50, 100) / 100;
    this.lineWidth = self.lineWidth;
    this.targetRadius = 1;
  };

  Firework.prototype.update = function (index) {
    self.ctx.lineWidth = this.lineWidth;

    const vx = Math.cos(this.angle) * this.speed;
    const vy = Math.sin(this.angle) * this.speed;
    this.speed *= 1 + this.acceleration;
    this.coordLast[2].x = this.coordLast[1].x;
    this.coordLast[2].y = this.coordLast[1].y;
    this.coordLast[1].x = this.coordLast[0].x;
    this.coordLast[1].y = this.coordLast[0].y;
    this.coordLast[0].x = this.x;
    this.coordLast[0].y = this.y;

    if (self.showTarget) {
      if (this.targetRadius < 8) {
        this.targetRadius += .25 * self.dt;
      } else {
        this.targetRadius = 1 * self.dt;
      }
    }

    if (this.startX >= this.targetX) {
      if (this.x + vx <= this.targetX) {
        this.x = this.targetX;
        this.hitX = true;
      } else {
        this.x += vx * self.dt;
      }
    } else {
      if (this.x + vx >= this.targetX) {
        this.x = this.targetX;
        this.hitX = true;
      } else {
        this.x += vx * self.dt;
      }
    }

    if (this.startY >= this.targetY) {
      if (this.y + vy <= this.targetY) {
        this.y = this.targetY;
        this.hitY = true;
      } else {
        this.y += vy * self.dt;
      }
    } else {
      if (this.y + vy >= this.targetY) {
        this.y = this.targetY;
        this.hitY = true;
      } else {
        this.y += vy * self.dt;
      }
    }

    if (this.hitX && this.hitY) {
      var randExplosion = rand(0, 9);
      self.createParticles(this.targetX, this.targetY, this.hue);
      self.fireworks.splice(index, 1);
    }
  };

  Firework.prototype.draw = function () {
    self.ctx.lineWidth = this.lineWidth;

    var coordRand = (rand(1, 3) - 1);
    self.ctx.beginPath();
    self.ctx.moveTo(Math.round(this.coordLast[coordRand].x), Math.round(this.coordLast[coordRand].y));
    self.ctx.lineTo(Math.round(this.x), Math.round(this.y));
    self.ctx.closePath();
    self.ctx.strokeStyle = 'hsla(' + this.hue + ', 100%, ' + this.brightness + '%, ' + this.alpha + ')';
    self.ctx.stroke();

    if (self.showTarget) {
      self.ctx.save();
      self.ctx.beginPath();
      self.ctx.arc(Math.round(this.targetX), Math.round(this.targetY), this.targetRadius, 0, Math.PI * 2, false)
      self.ctx.closePath();
      self.ctx.lineWidth = 1;
      self.ctx.stroke();
      self.ctx.restore();
    }

    if (self.showShockwave) {
      self.ctx.save();
      self.ctx.translate(Math.round(this.x), Math.round(this.y));
      self.ctx.rotate(this.shockwaveAngle);
      self.ctx.beginPath();
      self.ctx.arc(0, 0, 1 * (this.speed / 5), 0, Math.PI, true);
      self.ctx.strokeStyle = 'hsla(' + this.hue + ', 100%, ' + this.brightness + '%, ' + rand(25, 60) / 100 + ')';
      self.ctx.lineWidth = this.lineWidth;
      self.ctx.stroke();
      self.ctx.restore();
    }
  };

  /*=============================================================================*/
  /* Create Fireworks
  /*=============================================================================*/
  self.createFireworks = function (startX, startY, targetX, targetY) {
    self.fireworks.push(new Firework(startX, startY, targetX, targetY));
  };

  /*=============================================================================*/
  /* Update Fireworks
  /*=============================================================================*/
  self.updateFireworks = function () {
    var i = self.fireworks.length;
    while (i--) {
      var f = self.fireworks[i];
      f.update(i);
    };
  };

  /*=============================================================================*/
  /* Draw Fireworks
  /*=============================================================================*/
  self.drawFireworks = function () {
    var i = self.fireworks.length;
    while (i--) {
      var f = self.fireworks[i];
      f.draw();
    };
  };

  /*=============================================================================*/
  /* Clear Canvas
  /*=============================================================================*/
  self.clear = function () {
    self.particles = [];
    self.fireworks = [];
    self.ctx.clearRect(0, 0, self.cw, self.ch);
  };

  /*=============================================================================*/
  /* Delta
  /*=============================================================================*/
  self.updateDelta = function () {
    var newTime = Date.now();
    self.dt = (newTime - self.oldTime) / 16;
    self.dt = (self.dt > 5) ? 5 : self.dt;
    self.oldTime = newTime;
  }

  /*=============================================================================*/
  /* Main Loop
  /*=============================================================================*/
  self.canvasLoop = function () {
    requestAnimFrame(self.canvasLoop, self.canvas);
    self.updateDelta();
    self.ctx.globalCompositeOperation = 'destination-out';
    self.ctx.fillStyle = 'rgba(0,0,0,' + self.clearAlpha / 100 + ')';
    self.ctx.fillRect(0, 0, self.cw, self.ch);
    self.ctx.globalCompositeOperation = 'lighter';
    self.updateFireworks();
    self.updateParticles();
    self.drawFireworks();
    self.drawParticles();
  };

  self.init();

  self.launch = function () {
    var initialLaunchCount = 10;
    while (initialLaunchCount--) {
      setTimeout(function () {
        self.fireworks.push(new Firework(self.cw / 2, self.ch, rand(50, self.cw - 50), rand(50, self.ch / 2) - 50));
      }, initialLaunchCount * 200);
    }
  }
}

// global variable
const fworks = new Fireworks();

