import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setModal, openModal, LEADERBOARD_MODAL } from '../redux/modalSlice'

import * as freePlaySlice from './freePlaySlice'
import * as fourFreePlaySlice from './fourFreePlaySlice'
import * as raceModeSlice from './raceModeSlice'

export const MODE_SELECT = 'MODE_SELECT'
export const FREE_PLAY_MODE = 'FREE_PLAY_MODE'
export const FOUR_FREE_PLAY_MODE = 'FOUR_FREE_PLAY_MODE'
export const RACE_MODE = 'RACE_MODE'

const initialState = {
  allPhotos: [],
  solvedPhotos: [],
  currentPhoto: -1,
  currentMode: FREE_PLAY_MODE,
  leaderboard: {},
  loading: {
    leaderboard: true,
    submittingToLeaderboard: false
  }
}

export const exitCurrentMode = (data) => (dispatch, getState) => {
  dispatch(freePlaySlice.exitFreePlay())
  dispatch(raceModeSlice.exitRaceMode())
  dispatch(fourFreePlaySlice.exitFourFreePlay())
  dispatch(setMode(MODE_SELECT))
}

export const solvedCurrentPhoto = (data) => (dispatch, getState) => {
  const state = getState().root
  if (state.currentMode == 'FREE_PLAY_MODE') {
    dispatch(freePlaySlice.solvedCurrentPhoto())
  }
  if (state.currentMode == 'FOUR_FREE_PLAY_MODE') {
    dispatch(fourFreePlaySlice.solvedCurrentPhoto())
  }
  else if (state.currentMode == 'RACE_MODE') {
    dispatch(raceModeSlice.solvedCurrentPhoto())
  }
}

export const saveLeaderboardEntryToDB = createAsyncThunk(
  'root/submitScore',
  async (data, thunkAPI) => {
    const { name, score, mode } = data
    const payload = JSON.stringify({
      name: name,
      score: score,
      mode: mode
    })
    const response = await fetch('https://animalscramble.wl.r.appspot.com/submit-score/',
      {
        method: 'POST', body: payload
      })
      .then((response) => response.json())

    thunkAPI.dispatch(setModal(LEADERBOARD_MODAL))
    thunkAPI.dispatch(openModal())
    return response
  }
)


export const getLeaderboard = createAsyncThunk(
  'root/getLeaderboard',
  async () => {
    const response = await fetch('https://animalscramble.wl.r.appspot.com/get-leaderboard/')
    return response.json()
  }
)

export const rootSlice = createSlice({
  name: 'root',
  initialState: initialState,
  reducers: {
    showNextPhoto: (state, action) => {
      state.currentPhoto = state.currentPhoto + 1
      // If we run out of photos, wrap around to the first photo again.
      if (state.currentPhoto >= state.allPhotos.length) {
        state.currentPhoto = 0
      }
    },
    addPhotos: (state, action) => {
      state.allPhotos = state.allPhotos.concat(action.payload)
    },
    setMode: (state, action) => {
      if (action.payload == MODE_SELECT) {
        const gridster = window.gridsterInstance
        if (gridster) {
          gridster.disable()
        }
      }
      state.currentMode = action.payload
    },
    savePhotoAsCompleted: (state, action) => {
      const updatedSolvedPhotos = state.solvedPhotos
      updatedSolvedPhotos.push(action.payload)
      state.solvedPhotos = updatedSolvedPhotos
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getLeaderboard.fulfilled, (state, { payload }) => {
        console.log(payload)
        state.leaderboard = payload
        state.loading.leaderboard = false
      })
      .addCase(getLeaderboard.rejected, (state, { payload }) => {
        console.log('Error getting leaderboard')
      })
      .addCase(getLeaderboard.pending, (state, { payload }) => {
        console.log('request is pending')
        state.loading.leaderboard = true
      })
      .addCase(saveLeaderboardEntryToDB.pending, (state, { payload }) => {
        console.log('save to leader pending')
        state.loading.submittingToLeaderboard = true
      })
      .addCase(saveLeaderboardEntryToDB.rejected, (state, { payload }) => {
        // TODO: show a toast error
        // TODO: show spinner when
        console.log('rejected save to leader')
        state.loading.submittingToLeaderboard = false
      })
      .addCase(saveLeaderboardEntryToDB.fulfilled, (state, { payload }) => {
        console.log('save to leaderboard fulfilled')
        state.leaderboard = payload
        state.loading.submittingToLeaderboard = false
      })
  }
})

// Action creators are generated for each reducer function
export const {
  addPhotos,
  savePhotoAsCompleted,
  setMode,
  showNextPhoto,
} = rootSlice.actions

export default rootSlice.reducer