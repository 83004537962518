import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { openModal, setModal, RACE_MODE_START_MODAL } from '../redux/modalSlice'
import { FOUR_FREE_PLAY_MODE, FREE_PLAY_MODE, saveLeaderboardEntryToDB } from '../redux/rootSlice'
import CustomButton from '../CustomButton'
import { profanity } from '@2toad/profanity';
import { generateNickname } from '../utils/utils'
import ShareButton, { MODES } from '../ShareButton'


// Handles both 4x4 and 3x3 freestyle.
export default function FreestyleAddToLeaderboardModal() {
    const submittingToLeaderboard = useSelector((state) => state.root.loading.submittingToLeaderboard)
    const [leaderboardName, setLeaderboardName] = useState(generateNickname())
    const leaderboard = useSelector((state) => state.root.leaderboard)
    const [nameIsInvalid, setNameIsInvalid] = useState(false)
    const currentMode = useSelector((state) => state.root.currentMode)
    let timeThisPuzzle = ''
    let shareMode = MODES.FREESTYLE_LEADERBOARD

    let specificLeaderboard = leaderboard['freeplay_mode']
    if (currentMode == FREE_PLAY_MODE) {
        timeThisPuzzle = useSelector((state) => state.freePlay.timeThisPuzzle)
    } else if (currentMode == FOUR_FREE_PLAY_MODE) {
        shareMode = MODES.FOUR_FREESTYLE_LEADERBOARD
        specificLeaderboard = leaderboard['four_freeplay_mode']
        timeThisPuzzle = useSelector((state) => state.fourFreePlay.timeThisPuzzle)
    }
    const dispatch = useDispatch()

    const isValidName = (name) => {
        if (profanity.exists(name)) {
            return false
        }
        return true
    }

    const saveLeaderboardEntry = () => {
        setNameIsInvalid(false)
        if (isValidName(leaderboardName)) {
            dispatch(saveLeaderboardEntryToDB({
                name: leaderboardName,
                score: timeThisPuzzle,
                mode: currentMode
            }
            ))
        }
        else {
            setNameIsInvalid(true)
        }
    }

    const rank = () => {
        let rank = 1;
        for (let i = 0; i < specificLeaderboard.length; i++) {
            if (specificLeaderboard[i].score > timeThisPuzzle) {
                return rank
            }
            rank = rank + 1
        }
        return rank
    }

    return (
        <>
            <h2>Wow!</h2>
            <div className="leaderboard-qualification-message">
                <h3>
                    ✨ You qualified for the leaderboard! ✨
                </h3>
                <p>Your score: <b>{timeThisPuzzle} seconds</b></p>
                <hr />
                <p>
                    Your nickname:
                    <input
                        type="text"
                        value={leaderboardName}
                        className="leader-name-input"
                        onChange={(event) => { setLeaderboardName(event.target.value) }}
                        maxLength={10}
                    />
                </p>
            </div>
            <p style={{
                display: 'flex',
                justifyContent: 'space-around'
            }}><CustomButton
                    text="Save and Continue"
                    onClick={() => saveLeaderboardEntry()}
                    spinner={submittingToLeaderboard}
                /><ShareButton mode={shareMode} rank={rank()} /></p>
            {nameIsInvalid && <p>Sorry, try another name</p>}
        </>
    )
}