
import PastPhotos from "../PastPhotos";
import LeftGameMenuDesktop from "./LeftGameMenuDesktop";
import ModeSelectMenu from "./ModeSelectMenu";
import Leaderboard from './Leaderboard'

import { useSelector, useDispatch } from 'react-redux'


export default function LeftMenuDesktop() {
    const currentMode = useSelector((state) => state.root.currentMode)

    const menu = {
        FREE_PLAY_MODE: <LeftGameMenuDesktop />,
        FOUR_FREE_PLAY_MODE: <LeftGameMenuDesktop />,
        RACE_MODE: <LeftGameMenuDesktop />,
        MODE_SELECT: <ModeSelectMenu />
    }

    return (
        <div className="col-lg-3 ms-auto desktop-only">
            {menu[currentMode]}
            <PastPhotos />
            <Leaderboard />
        </div>
    )
}