import { startFreePlayMode } from '../redux/freePlaySlice'
import { startFourFreePlayMode } from '../redux/fourFreePlaySlice'
import { setModal, RACE_MODE_START_MODAL, openModal } from '../redux/modalSlice'
import { useSelector, useDispatch } from 'react-redux'

export function ModeButton(props) {
    return (
        <div
            className="game-mode-button"
            onClick={props.onClick}
        >
            <div
                style={{ padding: '8px' }}
            >{props.icon}</div>
            <div style={{ padding: '0px', paddingLeft: '8px', textAlign: 'left' }}>
                <h5 style={{ margin: '0px' }}>{props.title}</h5>
                <div
                    style={{ fontSize: '14px' }}
                >{props.description}</div>
            </div>
        </div>
    )
}

export default function ModeSelectMenu() {
    const dispatch = useDispatch()

    return (
        <div
            className="rounded"
            style={{ backgroundColor: 'rgba(239, 239, 239, .6)', padding: '8px', marginBottom: '8px' }}
        >
            <h3 style={{ textAlign: 'center' }}>Select Mode</h3>
            <ModeButton
                onClick={() => dispatch(startFreePlayMode())}
                title="3x3 Freestyle"
                icon={<i class="fa-solid fa-frog" style={{ fontSize: '30px' }}></i>}
                description="No limits, try to get the fastest time."
            />
            <ModeButton
                onClick={() => dispatch(startFourFreePlayMode())}
                title="4x4 Freestyle"
                icon={<i class="fa-solid fa-hippo" style={{ fontSize: '30px' }}></i>}
                description="Test your limits with 4 by 4 puzzles."
            />

            <ModeButton
                onClick={() => {
                    dispatch(setModal(RACE_MODE_START_MODAL))
                    dispatch(openModal())
                }}
                title="Race Mode"
                icon={<i class="fa-solid fa-horse-head" style={{ fontSize: '30px' }}></i>}
                description="Race against the clock to solve 3 by 3 puzzles."
            />

        </div>
    )
}