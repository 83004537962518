
import { useSelector, useDispatch } from 'react-redux'
import ShareButton from './ShareButton'

const iconStyles = {
    width: '40px',
    background: '#a5a5a5',
    color: '#ffffff',
    textAlign: 'center',
    display: 'inline-block',
    margin: '2px'
}



export default function PastPhotos() {
    const allPhotos = useSelector((state) => state.root.allPhotos)
    const solvedPhotos = useSelector((state) => state.root.solvedPhotos)

    return (
        <div className="rounded"
            style={{
                backgroundColor: 'rgba(239, 239, 239, .6)',
                padding: '8px',
                marginBottom: '8px',
            }}>
            <p style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
            ><b>Solved: ({solvedPhotos.length})</b>
                <ShareButton />
            </p>
            <p>
                {!solvedPhotos.length ? (
                    <>
                        {/* <div style={iconStyles}><i class="fa-solid fa-dog"></i></div>
                        <div style={iconStyles}><i class="fa-solid fa-hippo"></i></div>
                        <div style={iconStyles}><i class="fa-solid fa-dragon"></i></div> */}
                        <div style={iconStyles}><i className="fa-solid fa-cat"></i></div>
                        {/* <div style={iconStyles}><i class="fa-solid fa-otter"></i></div>
                        <div style={iconStyles}><i class="fa-solid fa-kiwi-bird"></i></div> */}
                    </>
                ) : ""}
                {solvedPhotos.map((photo, index) => {
                    return <img
                        style={{ height: "40px", margin: '3px' }}
                        src={allPhotos[photo].image_url}
                    />
                })}
            </p>
        </div>
    )
}