import { useSelector, useDispatch } from 'react-redux'
import { modeToTitle } from './utils/utils'
import ReactModal from 'react-modal'


// props should have "photoTitle" and "photoLink" properties (both strings)
export function Caption(props) {
    return <div className="caption">
      {props.photoTitle} [<a href={props.photoLink} target="_blank">source</a>]
    </div>
}

// props should have a "photo" property, an object with a title and description(link)
export default function CaptionArea(props) {
    const dispatch = useDispatch()
    const currentMode = useSelector((state) => state.root.currentMode)

    // Show the caption only after the user has solved a puzzle and is transitioning
    // to the next one (including if they have paused the transition).
    const shouldShowCaption = useSelector((state) => {
        if (currentMode == 'FREE_PLAY_MODE') {
            return state.freePlay.isPaused || state.freePlay.isTransitioningToNextPuzzle;
        }
        if (currentMode == 'RACE_MODE') {
            return state.raceMode.isTransitioningToNextPuzzle;
        }
        return false;
    })

    return (
        <>
            {
                shouldShowCaption && props.photo && <Caption photoTitle={props.photo.title} photoLink={props.photo.description}/>
            }
        </>
    )
}
