import { useSelector, useDispatch } from 'react-redux'
import { unpauseFreePlay, pauseFreePlay } from '../redux/freePlaySlice'
import { unpauseFourFreePlay, pauseFourFreePlay } from '../redux/fourFreePlaySlice'
import { openModal, setModal, RACE_MODE_START_MODAL } from '../redux/modalSlice'


export function UnpauseFreePlayButton() {
    const dispatch = useDispatch()
    const currentMode = useSelector((state) => state.root.currentMode)
    const onClick = {
        FREE_PLAY_MODE: unpauseFreePlay,
        FOUR_FREE_PLAY_MODE: unpauseFourFreePlay
    }[currentMode]
    return (
        <button
            className="secondary-button continue-button"
            onClick={() => dispatch(onClick())}
            style={{ width: '100%' }}>Continue
        </button>
    )
}

export function FreePlayCountdownMessage() {
    return (
        <p className="freeplay-countdown-message">Next image coming up in 3 seconds...</p>
    )
}

export function FreePlayIsPausedMessage() {
    return (
        <p className="freeplay-countdown-message">Next image coming up in 3 seconds...</p>
    )
}

export function PauseFreePlayButton() {
    const dispatch = useDispatch()
    const currentMode = useSelector((state) => state.root.currentMode)
    const onClick = {
        FREE_PLAY_MODE: pauseFreePlay,
        FOUR_FREE_PLAY_MODE: pauseFourFreePlay
    }[currentMode]
    return (
        <button
            className="secondary-button pause-button"
            onClick={() => dispatch(onClick())}
            style={{ width: '100%' }}>Pause
        </button>
    )
}

export function SkipButton() {
    const dispatch = useDispatch()
    const currentMode = useSelector((state) => state.root.currentMode)
    const onClick = {
        FREE_PLAY_MODE: unpauseFreePlay,
        FOUR_FREE_PLAY_MODE: unpauseFourFreePlay
    }[currentMode]
    return (
        <button
            className="secondary-button skip-button"
            onClick={() => dispatch(onClick())}
            style={{ width: '100%' }}>Skip
        </button>
    )
}

export function ReplayRaceModeButton() {
    const startAgain = () => {
        dispatch(setModal(RACE_MODE_START_MODAL))
        dispatch(openModal())
    }

    const dispatch = useDispatch()
    return (
        <button
            className="secondary-button replay-button"
            onClick={() => startAgain()}
            style={{ width: '100%' }}><i class="fa-solid fa-rotate-right"
                style={{ paddingRight: '8px' }}></i> Play Again
        </button>
    )
}

export default function PauseSkipArea() {
    const freePlayIsPaused = useSelector((state) => state.freePlay.isPaused)
    const freePlayIsTransitioningToNextPuzzle = useSelector((state) => state.freePlay.isTransitioningToNextPuzzle)
    const fourFreePlayIsPaused = useSelector((state) => state.fourFreePlay.isPaused)
    const fourFreePlayIsTransitioningToNextPuzzle = useSelector((state) => state.fourFreePlay.isTransitioningToNextPuzzle)

    const timeRemaining = useSelector((state) => state.raceMode.timeRemaining)
    const currentMode = useSelector((state) => state.root.currentMode)
    const dispatch = useDispatch()

    const renderButton = () => {
        if (currentMode == 'FREE_PLAY_MODE') {
            if (freePlayIsPaused) {
                return <UnpauseFreePlayButton />
            }
            else if (freePlayIsTransitioningToNextPuzzle) {
                return <>
                    <FreePlayCountdownMessage />
                    <PauseFreePlayButton />
                </>
            }
            else {
                return <SkipButton />
            }
        }
        else if (currentMode == 'FOUR_FREE_PLAY_MODE') {
            if (fourFreePlayIsPaused) {
                return <UnpauseFreePlayButton />
            }
            else if (fourFreePlayIsTransitioningToNextPuzzle) {
                return <>
                    <FreePlayCountdownMessage />
                    <PauseFreePlayButton />
                </>
            }
            else {
                return <SkipButton />
            }
        }
        if (currentMode == 'RACE_MODE' && timeRemaining <= 0) {
            return <ReplayRaceModeButton />
        }
        else {
            console.log('Unknown mode!', currentMode)
        }
        return <></>
    }

    return (
        <>
            {renderButton()}
        </>
    )
}