import { createSlice } from '@reduxjs/toolkit'

import { setMode, showNextPhoto, FREE_PLAY_MODE, savePhotoAsCompleted, getLeaderboard } from './rootSlice'
import { setModal, openModal, FREESTYLE_ADD_TO_LEADERBOARD_MODAL } from './modalSlice'

const initialState = {
    totalSolved: 0,
    bestTime: null,
    timeThisPuzzle: 0,
    currentTimer: null,
    isPaused: false,
    isTransitioningToNextPuzzle: false,
}

export const freePlaySlice = createSlice({
    name: 'freePlay',
    initialState: initialState,
    reducers: {
        setPaused: (state, action) => {
            state.isPaused = action.payload
        },
        setIsTransitioningToNextPuzzle: (state, action) => {
            state.isTransitioningToNextPuzzle = action.payload
        },
        incrementNumberSolved: (state) => {
            state.totalSolved = state.totalSolved + 1
        },
        restartTimer: (state) => {
            state.timeThisPuzzle = 0
        },
        incrementTimer: (state) => {
            state.timeThisPuzzle = state.timeThisPuzzle + 1
        },
        saveTimer: (state, action) => {
            state.currentTimer = action.payload
        },
        saveBestTime: (state, action) => {
            state.bestTime = state.timeThisPuzzle
        },
        exitFreePlay: (state, action) => {
            clearInterval(state.currentTimer)
            state.totalSolved = 0
            state.bestTime = null
            state.timeThisPuzzle = 0
            state.currentTimer = null
            state.isTransitioningToNextPuzzle = false
        },
    },
})

const qualifiesForLeaderboard = (rootState, timeThisPuzzle) => {
    if (!rootState.loading.leaderboard) {
        if (rootState.leaderboard['freeplay_mode'].length < 10) {
            return true
        }
        const threshold = rootState.leaderboard['freeplay_mode'].slice(-1)[0]['score']
        if (timeThisPuzzle < threshold) {
            return true
        }
    }
    return false
}

export const unpauseFreePlay = (data) => (dispatch, getState) => {
    // Triggers the next puzzle immediately. Also used to skip the current puzzle.

    // Clears the current timer.
    clearInterval(getState().freePlay.currentTimer)
    dispatch(saveTimer(null))

    // Start the next puzzle
    const time = setInterval(() => {
        dispatch(incrementTimer())
    }, 1000)
    dispatch(saveTimer(time))

    dispatch(showNextPhoto())
    dispatch(restartTimer())
    dispatch(setPaused(false))
    dispatch(setIsTransitioningToNextPuzzle(false))
}

export const pauseFreePlay = (data) => (dispatch, getState) => {
    dispatch(setPaused(true))
    clearInterval(getState().freePlay.currentTimer)
    dispatch(saveTimer(null))
}

export const solvedCurrentPhoto = (data) => (dispatch, getState) => {
    // First clean up state from this puzzle
    const state = getState().freePlay
    dispatch(incrementNumberSolved())
    dispatch(savePhotoAsCompleted(getState().root.currentPhoto))
    clearInterval(state.currentTimer)
    dispatch(saveTimer(null))

    // Next, optionally save the time as the best time and tell them if they've
    // qualified for the leaderboard.
    if (!state.bestTime || state.timeThisPuzzle < state.bestTime) {
        dispatch(saveBestTime())
    }

    if (qualifiesForLeaderboard(getState().root, state.timeThisPuzzle)) {
        console.log('qualifies!')
        dispatch(setModal(FREESTYLE_ADD_TO_LEADERBOARD_MODAL))
        dispatch(openModal())
        dispatch(setPaused(true))
        return
    }

    // Last, trigger the next puzzle in 3 seconds.

    dispatch(setIsTransitioningToNextPuzzle(true))
    const time = setTimeout(() => {
        dispatch(showNextPhoto())
        dispatch(restartTimer())
        dispatch(setPaused(false))
        dispatch(setIsTransitioningToNextPuzzle(false))
        const time = setInterval(() => {
            dispatch(incrementTimer())
        }, 1000)
        dispatch(saveTimer(time))
    }, 3000)

    dispatch(saveTimer(time))


}

export const startFreePlayMode = (data) => (dispatch, getState) => {
    dispatch(showNextPhoto())
    const time = setInterval(() => {
        dispatch(incrementTimer())
    }, 1000)
    dispatch(saveTimer(time))
    dispatch(setMode(FREE_PLAY_MODE))
    dispatch(getLeaderboard())
}

// Action creators are generated for each reducer function
export const {
    incrementNumberSolved,
    incrementTimer,
    saveTimer,
    exitFreePlay,
    saveBestTime,
    restartTimer,
    setPaused,
    setIsTransitioningToNextPuzzle
} = freePlaySlice.actions

export default freePlaySlice.reducer