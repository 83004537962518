export default function CustomButton(props) {
    return (
        <button
            className={`primary-button ${props.className}`}
            style={props.style}
            onClick={() => props.onClick()}>
            {props.spinner && (
                <div style={{
                    textAlign: 'center',
                    display: 'inline',
                    paddingRight: '8px'
                }}>
                    <div className="spinner-border spinner-border-sm" role="status"
                        style={{
                            borderRightColor: 'transparent',
                        }}>
                        <span></span>
                    </div>
                </div>
            )}
            {props.text}
        </button>
    )
}