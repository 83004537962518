import ReactModal from 'react-modal'
import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import RaceModeEndModal from '../Modals/RaceModeEndModal'
import RaceModeStartModal from '../Modals/RaceModeStartModal'
import { closeModal } from '../redux/modalSlice'
import ModeSelectMenu from './ModeSelectMenu'

export default function ModeSelectMenuMobile() {
    const desktopModalIsOpen = useSelector((state) => state.modal.isOpen)
    const currentMode = useSelector((state) => state.root.currentMode)

    // Only show the mobile modal if there is no other modal open and
    // we are in the mode select menu.
    const shouldDisplay = (currentMode, desktopModalIsOpen) => {
        let isOpen = false
        if (currentMode == 'MODE_SELECT') {
            isOpen = desktopModalIsOpen ? false : true
        }
        return isOpen
    }

    const style = {
        width: 'calc(100% - 32px)',
        padding: '0px',
        zIndex: '9999',
    }

    if (!shouldDisplay(currentMode, desktopModalIsOpen)) {
        style['display'] = 'none'
    }

    useEffect(() => {
        const overlayElement = document.getElementById('mobile-modal-container')
        if (shouldDisplay(currentMode, desktopModalIsOpen)) {
            overlayElement.classList.add('visible');
        }
        else {
            overlayElement.classList.remove('visible');
        }
    }, [currentMode, desktopModalIsOpen])

    return (
        <div
            className="modal rounded mobile-only"
            style={style}
        >
            <ModeSelectMenu />
        </div>
    )
}