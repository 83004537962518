import { createSlice } from '@reduxjs/toolkit'

export const RACE_MODE_END_MODAL = 'RACE_MODE_END_MODAL'
export const RACE_MODE_START_MODAL = 'RACE_MODE_START_MODAL'
export const LEADERBOARD_MODAL = 'LEADERBOARD_MODAL'
export const FREESTYLE_ADD_TO_LEADERBOARD_MODAL = 'FREESTYLE_ADD_TO_LEADERBOARD_MODAL'

export const modalSlice = createSlice({
    name: 'modal',
    initialState: {
        isOpen: false,
        currentModal: null,
    },
    reducers: {
        setModal: (state, action) => {
            state.currentModal = action.payload
        },
        openModal: (state, action) => {
            state.isOpen = true
        },
        closeModal: (state, action) => {
            state.isOpen = false
        }
    },
})


// Action creators are generated for each reducer function
export const {
    openModal,
    closeModal,
    setModal
} = modalSlice.actions

export default modalSlice.reducer