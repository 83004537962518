import { displaySecondsAsTime } from '../../utils/utils'
import { useSelector, useDispatch } from 'react-redux'


export default function FreePlayTopMenu() {
    const totalSolved = useSelector((state) => state.fourFreePlay.totalSolved)
    const timeThisPuzzle = useSelector((state) => state.fourFreePlay.timeThisPuzzle)
    const bestTime = useSelector((state) => state.fourFreePlay.bestTime)

    return (
        <>
            <p style={{ width: '100%', margin: '0px' }}>
                <div
                    style={{ float: "left", paddingRight: "8px", textAlign: 'center' }}>
                    <p style={{ margin: '0px', fontSize: '14px' }}>Best Time</p>
                    <p style={{ margin: '0px', fontSize: '22px', fontWeight: 'bold' }}>{bestTime ? displaySecondsAsTime(bestTime) : '-'}</p>
                </div>
                <div
                    style={{ paddingRight: "16px", float: "right", textAlign: 'center' }}>
                    <p style={{ margin: '0px', fontSize: '14px' }}>Time</p>
                    <p style={{ margin: '0px', fontSize: '22px', fontWeight: 'bold' }}>{displaySecondsAsTime(timeThisPuzzle)}</p>
                </div>
                <div
                    style={{ float: "right", paddingRight: "8px", textAlign: 'center' }}>
                    <p style={{ margin: '0px', fontSize: '14px' }}>Solved:</p>
                    <p style={{ margin: '0px', fontSize: '22px', fontWeight: 'bold' }}>{totalSolved}</p>
                </div>
            </p>
        </>
    )
}