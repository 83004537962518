import { startRaceMode } from "../redux/raceModeSlice"
import { useSelector, useDispatch } from 'react-redux'
import { useState, useRef, useEffect } from "react"
import { closeModal } from '../redux/modalSlice'


export default function RaceModeStartModal() {
    const countdownTime = useRef(3)
    const timerRef = useRef(null)
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)
    const [countdown, setCountdown] = useState(3)

    const letsGo = () => {
        setIsLoading(true)
        timerRef.current = setInterval(() => {
            countdownTime.current = countdownTime.current - 1
            setCountdown(countdownTime.current)
            if (countdownTime.current <= 0) {
                dispatch(closeModal())
                clearInterval(timerRef.current)
                dispatch(startRaceMode())
            }
        }, 1000)
    }

    useEffect(() => {
        // Clear the interval when the component unmounts
        return () => clearInterval(timerRef.current);
    }, []);

    return (
        <>
            {isLoading ? (
                <h2>{countdownTime.current}</h2>
            ) : (
                <>
                    <h2>Ready?</h2>
                    <h3 style={{
                        fontSize: '18px'
                    }}>Solve as many puzzles as you can in 2 minutes</h3>
                    <button className="primary-button" onClick={() => letsGo()}>Let's Go <i class="fa-solid fa-right"></i></button>
                </>
            )}
        </>
    )
}