import { useSelector, useDispatch } from 'react-redux'
import { exitCurrentMode } from '../redux/rootSlice'
import { modeToTitle } from '../utils/utils'
import PauseSkipArea from './PauseSkipArea'

import ReactModal from 'react-modal'



export default function LeftGameMenuDesktop() {
    const dispatch = useDispatch()
    const currentMode = useSelector((state) => state.root.currentMode)

    const title = modeToTitle[currentMode]
    const exit = () => {
        dispatch(exitCurrentMode())
    }

    return (
        <>
            <div
                className="rounded"
                style={{ backgroundColor: 'rgba(239, 239, 239, .6)', padding: '8px', marginBottom: '8px' }}
            >
                <h2 style={{ textAlign: 'center' }}>{title}</h2>
                <p><PauseSkipArea /></p>
                <p><button
                    className="primary-button"
                    onClick={() => exit()}
                    style={{ width: '100%' }}>Switch Mode</button></p>
            </div>
        </>
    )
}