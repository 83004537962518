import FourFreePlayTopMenu from "./FourFreePlayTopMenu";
import FreePlayTopMenu from "./FreePlayTopMenu";
import ModeSelectMenu from "../ModeSelectMenu";
import RaceModeTopMenu from "./RaceModeTopMenu";

import { useSelector, useDispatch } from 'react-redux'

function TopPlaceHolder() {
    return (
        <p style={{ width: '100%', margin: '0px' }}>
            <div
                style={{ float: "left", paddingRight: "8px", textAlign: 'center' }}>
                <p style={{ margin: '0px', fontSize: '14px' }}>&nbsp;</p>
                <p style={{ margin: '0px', fontSize: '22px', fontWeight: 'bold' }}>&nbsp;</p>
            </div>
        </p>
    )
}

export default function TopMenu() {
    const currentMode = useSelector((state) => state.root.currentMode)

    const menu = {
        FREE_PLAY_MODE: <FreePlayTopMenu />,
        FOUR_FREE_PLAY_MODE: <FourFreePlayTopMenu />,
        RACE_MODE: <RaceModeTopMenu />,
        MODE_SELECT: <TopPlaceHolder />
    }

    return (
        <div className="ms-auto"
            style={{
                borderBottom: '1px solid rgb(105 166 154)',
                marginBottom: '16px'
            }}>
            <div
                style={{
                    display: 'inline-block',
                    width: '100%'
                }}>
                {menu[currentMode]}
            </div>
        </div>
    )
}