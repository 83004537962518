import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'


export const MODES = {
    'FREESTYLE_LEADERBOARD': 'FREESTYLE_LEADERBOARD',
    'FOUR_FREESTYLE_LEADERBOARD': 'FOUR_FREESTYLE_LEADERBOARD',
    'RACE_LEADERBOARD': 'RACE_LEADERBOARD'
}

export default function ShareButton(props) {
    const [currentText, setCurrentText] = useState('Share')
    const solvedPhotos = useSelector((state) => state.root.solvedPhotos)

    const getTextToShare = () => {
        const mode = props.mode ? props.mode : 'DEFAULT'
        if (mode == MODES.FREESTYLE_LEADERBOARD ||
            mode == MODES.RACE_LEADERBOARD ||
            mode == MODES.FOUR_FREESTYLE_LEADERBOARD) {
            return `🦊🐇🐹
I'm #${props.rank} on the leaderboard on AnimalScramble.com. Can you beat me?
🐷🦓🐶`
        }
        else {
            // Default mode (the share button in the solved box that is always present)
            let pluralize = ''
            if (solvedPhotos.length > 1) {
                pluralize = 's'
            }
            if (solvedPhotos.length != 0) {
                return `🦊🐇🐹
I unscrambled ${solvedPhotos.length} puzzle${pluralize} on AnimalScramble.com. Can you beat my streak?
🐷🦓🐶`
            } else {
                return `🦊🐇🐹
I'm unscrambling puzzles on AnimalScramble.com. Can you beat my streak?
🐷🦓🐶`
            }
        }
    }

    const shareClick = async () => {
        console.log('Shared')
        // TODO: track this action
        const shareData = {
            title: "AnimalScramble",
            text: getTextToShare(),
        };

        if (navigator.share) {
            await navigator.share(shareData).then(
            );
        } else {
            navigator.clipboard.writeText(getTextToShare()).then(() => {
                console.log('write text to clip')
                setCurrentText('Copied!')
            })
        }
        setTimeout(() => {
            setCurrentText('Share')
        }, 3000)
    }
    return (
        <div className='rounded'
            style={{
                backgroundColor: '#2196f3',
                color: 'white',
                padding: '4px 8px',
                cursor: 'pointer'
            }}
            onClick={() => shareClick()}
        >{currentText} <i class="fa-solid fa-share-nodes"></i></div>

    )
}