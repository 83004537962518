import { useSelector, useDispatch } from 'react-redux'
import React, { useEffect, useState } from "react";
import { exitCurrentMode } from '../redux/rootSlice'
import { modeToTitle } from '../utils/utils'
import PastPhotos from "../PastPhotos";
import PauseSkipArea from './PauseSkipArea';
import Leaderboard from './Leaderboard';

export default function BottomMenuMobile() {
    const dispatch = useDispatch()
    const currentMode = useSelector((state) => state.root.currentMode)
    // for listening
    const timeThisPuzzle = useSelector((state) => state.freePlay.timeThisPuzzle)
    const timeThisPuzzleFour = useSelector((state) => state.fourFreePlay.timeThisPuzzle)
    const timeRemaining = useSelector((state) => state.raceMode.timeRemaining)
    const title = modeToTitle[currentMode]
    const exit = () => {
        dispatch(exitCurrentMode())
    }

    useEffect(() => {
        // helps rerender height
    }, [timeThisPuzzle, timeRemaining, timeThisPuzzleFour])

    const getStyle = () => {
        const unused = timeRemaining;
        const unused2 = timeThisPuzzle;
        let currentStyle = {
            overflow: 'scroll',
            paddingBottom: '72px'
        }
        const gridWrapper = document.getElementById("gridWrapper")
        let gridHeight = 200
        if (gridWrapper) {
            gridHeight = gridWrapper.offsetHeight
        }
        const subtractor = gridHeight + 77 + 76
        currentStyle['height'] = `calc(100vh - ${subtractor}px)`
        return currentStyle
    }

    return (
        <div className="mobile-only"
            style={getStyle()}>
            <div
                className="rounded mobile-only"
                style={{
                    backgroundColor: 'rgba(239, 239, 239, .6)',
                    padding: '8px',
                    marginTop: '16px',
                    marginBottom: '8px'
                }}
            >
                <h2 style={{ textAlign: 'center' }}>{title}</h2>
                <p><PauseSkipArea /></p>
                <p><button
                    className="primary-button"
                    onClick={() => exit()}
                    style={{ width: '100%' }}>Switch Game</button></p>
            </div>
            <PastPhotos />
            <Leaderboard />
        </div>
    )
}